import React, { useEffect, useState, useRef } from 'react';
import Slider from 'react-slick';
import { motion, AnimatePresence } from 'framer-motion';
import studio1Mobile from 'assets/images/studio1_mobile.jpg';
import studio1MobileWebp from 'assets/images/studio1_mobile.jpg?as=webp';
import studio2Mobile from 'assets/images/studio2_mobile.jpg';
import studio2MobileWebp from 'assets/images/studio2_mobile.jpg?as=webp';
import studio3Mobile from 'assets/images/studio3_mobile.jpg';
import studio3MobileWebp from 'assets/images/studio3_mobile.jpg?as=webp';
import studio1 from 'assets/images/studio1.jpg';
import studio1Webp from 'assets/images/studio1.jpg?as=webp';
import studio2 from 'assets/images/studio2.jpg';
import studio2Webp from 'assets/images/studio2.jpg?as=webp';
import studio3 from 'assets/images/studio3.jpg';
import studio3Webp from 'assets/images/studio3.jpg?as=webp';
import milaFrontView from 'assets/images/mila-front-view.png';
import milaFrontViewWebp from 'assets/images/mila-front-view.png?as=webp';
import priceDel from 'assets/images/price-del.png';
import LazyLoad from 'react-lazyload';
import { useSelector } from 'react-redux';
import loadable from '@loadable/component';
import { CURRENCY_SIGN, PRODUCTS_ID_MAPPING, SUPPORTED_REGIONS } from '../../../shared/enums';
import { getPerfectSquare, getTrue } from '../../../shared/utils';
import { selectLocalizationData } from '../../features/localization/localizationSlice';
import {
  selectRegionalPricesData, selectRegionalPricesError,
} from '../../features/regionalPrices/regionalPricesSlice';
import generateKey from '../../../shared/utils/generateKey';

const FadeIn = loadable(() => import('../common/FadeIn'));

/**
 * @param settings
 * @param productSlider
 * @param localization
 * @param measure
 * @param sign
 * @param x1unit
 * @param x2unit
 * @param x3unit
 * @returns {JSX.Element}
 * @constructor
 */
const ProductBlockItems = ({
  settings,
  productSlider,
  localization,
  measure,
  sign,
  x1unit,
  x2unit,
  x3unit,
}) => (
  <Slider className='product-block__items' {...settings} ref={productSlider}>
    <div
      className='product-block__items-item '
    >
      <h3 className='product-block__items-title'>
        <strong>The Studio</strong>
        {' '}
        1x Mila
      </h3>
      <div
        className='price-card'
      >
        <div className='price-card__inner'>
          <figure className='price-card__image'>
            <LazyLoad>
              <FadeIn>
                <picture>
                  <source srcSet={`${milaFrontViewWebp} 1x`} type='image/webp' />
                  <source srcSet={`${milaFrontView} 1x`} type='image/png' />
                  <img
                    src={milaFrontView}
                    alt='mila-front-view'
                  />
                </picture>
              </FadeIn>
            </LazyLoad>
          </figure>
          <h3 className='price-card__title'>
            <strong>The Studio</strong>
            {' '}
            1x Mila
          </h3>
          <div className='price-card__description'>
            <p>
              Ideal for a studio apartment or any room up to
              {' '}
              {getPerfectSquare(1000, localization.region, measure)}
              {' '}
              {measure}
              <sup>2</sup>
              .
            </p>
          </div>
          {(getTrue(ENABLE_PRODUCT_BLOCK_DISCOUNT) && x1unit.discount_price)
            ? (
              <div className='price-card__footer'>
                <span
                  className='price-card__price'
                  style={{ width: '100%' }}
                >
                  <strong>
                    <span>
                      <img
                        src={priceDel}
                        alt='old-price'
                      />
                      {sign}
                      {x1unit.price}
                    </span>
                    {sign}
                    {x1unit.discount_price}
                  </strong>
                  <strong />
                </span>
                <a href='/buy' className='btn btn--small'>Order Now</a>
              </div>
            ) : (
              <div className='price-card__flex-footer'>
                <a href='/buy' className='btn btn--small'>Order Now</a>
                <span
                  className='price-card__price'
                >
                  <strong>
                    {sign}
                    {x1unit.price}
                  </strong>
                </span>
              </div>
            )}
        </div>
      </div>
    </div>
    <div
      className='product-block__items-item '
    >
      <h3
        className='product-block__items-title'
      >
        <strong>The One Bedroom</strong>
        {' '}
        2x Milas
      </h3>

      <div
        className='price-card'
      >
        <div className='price-card__inner'>
          <figure className='price-card__image'>
            <LazyLoad>
              <FadeIn>
                <picture>
                  <source srcSet={`${milaFrontViewWebp} 1x`} type='image/webp' />
                  <source srcSet={`${milaFrontView} 1x`} type='image/png' />
                  <img
                    src={milaFrontView}
                    alt='mila-front-view'
                  />
                </picture>
              </FadeIn>
            </LazyLoad>
          </figure>
          <motion.h3 className='price-card__title'>
            <strong>The One Bedroom</strong>
            {' '}
            2x Milas
          </motion.h3>
          <div className='price-card__description'>
            <p>
              Perfect for a 1-bedroom apartment or any 2 rooms up to
              {' '}
              {getPerfectSquare(1000, localization.region, measure)}
              {' '}
              {measure}
              <sup>2</sup>
              {' '}
              each.
            </p>
          </div>
          {(getTrue(ENABLE_PRODUCT_BLOCK_DISCOUNT) && x2unit.discount_price)
            ? (
              <div className='price-card__footer'>
                <span
                  className='price-card__price'
                  style={{ width: '100%' }}
                >
                  <strong>
                    <span>
                      <img
                        src={priceDel}
                        alt='old-price'
                      />
                      {sign}
                      {x2unit.price}
                    </span>
                    {sign}
                    {x2unit.discount_price}
                  </strong>
                </span>
                <a href='/buy' className='btn btn--small'>Order Now</a>
              </div>
            ) : (
              <div className='price-card__flex-footer'>
                <a href='/buy' className='btn btn--small'>Order Now</a>
                <span
                  className='price-card__price'
                >
                  <strong>
                    {sign}
                    {x2unit.price}
                  </strong>
                </span>
              </div>
            )}
        </div>
      </div>
    </div>
    <div
      className='product-block__items-item '
    >
      <h3
        className='product-block__items-title'
      >
        <strong>The Two Bedroom</strong>
        {' '}
        3x Milas
      </h3>

      <div
        className='price-card'
      >
        <div className='price-card__inner'>
          <figure className='price-card__image'>
            <LazyLoad>
              <FadeIn>
                <picture>
                  <source srcSet={`${milaFrontViewWebp} 1x`} type='image/webp' />
                  <source srcSet={`${milaFrontView} 1x`} type='image/png' />
                  <img
                    src={milaFrontView}
                    alt='mila-front-view'
                  />
                </picture>
              </FadeIn>
            </LazyLoad>
          </figure>
          <h3 className='price-card__title'>
            <strong>The Two Bedroom</strong>
            {' '}
            3x Milas
          </h3>
          <div className='price-card__description'>
            <p>
              Superb for a 2-bedroom apartment or any 3 rooms up to
              {' '}
              {getPerfectSquare(1000, localization.region, measure)}
              {' '}
              {measure}
              <sup>2</sup>
              {' '}
              each.
            </p>
          </div>
          {(getTrue(ENABLE_PRODUCT_BLOCK_DISCOUNT) && x3unit.discount_price)
            ? (
              <div className='price-card__footer'>
                <span
                  className='price-card__price'
                  style={{ width: '100%' }}
                >
                  <strong>
                    <span>
                      <img
                        src={priceDel}
                        alt='old-price'
                      />
                      {sign}
                      {x3unit.price}
                    </span>
                    {sign}
                    {x3unit.discount_price}
                  </strong>
                </span>
                <a href='/buy' className='btn btn--small'>Order Now</a>
              </div>
            ) : (
              <div className='price-card__flex-footer'>
                <a href='/buy' className='btn btn--small'>Order Now</a>
                <span
                  className='price-card__price'
                >
                  <strong>
                    {sign}
                    {x3unit.price}
                  </strong>
                </span>
              </div>
            )}
        </div>
      </div>
    </div>
  </Slider>
);

/**
 * @returns {null|JSX.Element}
 * @constructor
 */
const ProductBlock = () => {
  const localization = useSelector(selectLocalizationData);
  const regionalPrices = useSelector(selectRegionalPricesData);
  const error = useSelector(selectRegionalPricesError);

  const sign = CURRENCY_SIGN[localization.region] || CURRENCY_SIGN.US;
  const x1unit = regionalPrices.find(price => price.variant === PRODUCTS_ID_MAPPING.MILA_UNIT && parseInt(price.units, 10) === 1);
  const x2unit = regionalPrices.find(price => price.variant === PRODUCTS_ID_MAPPING.MILA_UNIT && parseInt(price.units, 10) === 2);
  const x3unit = regionalPrices.find(price => price.variant === PRODUCTS_ID_MAPPING.MILA_UNIT && parseInt(price.units, 10) === 3);

  const measure = [ SUPPORTED_REGIONS.US, SUPPORTED_REGIONS.CA ].includes(localization.region) ? 'ft' : 'm';
  const productSlider = useRef(null);
  const [ currentIndex, setCurrentIndex ] = useState(1);
  const [ sliderInit, setSliderInit ] = useState(false);
  const [ isMobile, setIsMobile ] = useState(false);
  const [ slideIndex, setSlideIndex ] = useState(0);

  const bodyRefs = useRef([]).current;
  const clickHandler = idx => {
    setCurrentIndex(idx);
  };

  const settings = {
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    dots: false,
    autoplay: false,
    speed: 1000,
    infinite: true,
    pauseOnHover: false,
    pauseOnFocus: false,
    variableWidth: true,
    tabIndex: '-1',
    onInit: () => { setSliderInit(true); setCurrentIndex(1); },
    beforeChange: (currentIndex, newIndex) => {
      setCurrentIndex(newIndex + 1);
    },

    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  useEffect(() => {
    function handleResize() {
      if (window.innerWidth <= 768) {
        setIsMobile(true);
      } else {
        setIsMobile(false);
      }
    }
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    if (!sliderInit || !isMobile) return;
    productSlider?.current.slickGoTo(slideIndex);
  }, [ isMobile, sliderInit, slideIndex ]);

  return error ? null : (
    <AnimatePresence>
      <section className='product-block'>
        <div className='container'>
          <div className='product-block__intro'>
            <h2 className='product-block__title'>Come home to Mila.</h2>
            <div className='product-block__description'>
              <p>
                Get one for your most lived-in area or tackle every room.
                The more Milas, the merrier.
              </p>
            </div>
          </div>
          <div className='product-block__inner'>
            <ul className='product-items'>
              <motion.li
                className={`product-items__item product-items__item--first ${currentIndex === 1 ? 'product-items__item--is-active' : ''}`}
              >
                <LazyLoad>
                  <FadeIn>
                    <picture>
                      <source media='(max-width: 768px)' srcSet={`${studio1MobileWebp} 1x`} type='image/webp' />
                      <source media='(max-width: 768px)' srcSet={`${studio1Mobile} 1x`} type='image/jpg' />

                      <source media='(min-width: 769px)' srcSet={`${studio1Webp} 1x`} type='image/webp' />
                      <source media='(min-width: 769px)' srcSet={`${studio1} 1x`} type='image/jpg' />
                      <img src={studio1} alt='Mila inside room' />
                    </picture>
                  </FadeIn>
                </LazyLoad>
              </motion.li>
              <motion.li
                className={`product-items__item product-items__item--second ${currentIndex === 2 ? 'product-items__item--is-active' : ''}`}
              >
                <LazyLoad>
                  <FadeIn>
                    <picture>
                      <source media='(max-width: 768px)' srcSet={`${studio2MobileWebp} 1x`} type='image/webp' />
                      <source media='(max-width: 768px)' srcSet={`${studio2Mobile} 1x`} type='image/jpg' />

                      <source media='(min-width: 769px)' srcSet={`${studio2Webp} 1x`} type='image/webp' />
                      <source media='(min-width: 769px)' srcSet={`${studio2} 1x`} type='image/jpg' />
                      <img alt='Mila inside room' src={studio2} />
                    </picture>
                  </FadeIn>
                </LazyLoad>
              </motion.li>
              <motion.li
                className={`product-items__item product-items__item--third ${currentIndex === 3 ? 'product-items__item--is-active' : ''}`}
              >
                <LazyLoad>
                  <FadeIn>
                    <picture>
                      <source media='(max-width: 768px)' srcSet={`${studio3MobileWebp} 1x`} type='image/webp' />
                      <source media='(max-width: 768px)' srcSet={`${studio3Mobile} 1x`} type='image/jpg' />

                      <source media='(min-width: 769px)' srcSet={`${studio3Webp} 1x`} type='image/webp' />
                      <source media='(min-width: 769px)' srcSet={`${studio3} 1x`} type='image/jpg' />
                      <img alt='Mila inside room' src={studio3} />
                    </picture>
                  </FadeIn>
                </LazyLoad>
              </motion.li>
            </ul>
            {
              isMobile
                ? (
                  <ProductBlockItems
                    settings={settings}
                    productSlider={productSlider}
                    localization={localization}
                    measure={measure}
                    sign={sign}
                    x1unit={x1unit}
                    x2unit={x2unit}
                    x3unit={x3unit}
                  />
                )
                : (
                  <div className='product-block__items'>
                    <div
                      key={generateKey('product-item')}
                      className={`product-block__items-item ${currentIndex === 1 ? 'product-block__items-item--is-active' : ''}`}
                      ref={el => bodyRefs[1] = el}
                      onClick={() => setSlideIndex(0)}
                    >
                      <motion.h3
                        className='product-block__items-title'
                        onClick={() => clickHandler(1)}
                        initial={{ height: 0 }}
                        animate={{ height: (currentIndex === 2 || currentIndex === 3) ? 'auto' : 0 }}
                        transition={{ duration: 0.5 }}
                        style={{ overflow: 'hidden' }}
                      >
                        <strong>The Studio</strong>
                        {' '}
                        1x Mila
                      </motion.h3>
                      <motion.div
                        className='price-card'
                        initial={{ height: 0 }}
                        animate={{ height: currentIndex === 1 ? 'auto' : 0 }}
                        transition={{ duration: 0.5 }}
                        style={{ overflow: 'hidden' }}
                      >
                        <div className='price-card__inner'>
                          <figure className='price-card__image'>
                            <LazyLoad>
                              <FadeIn>
                                <picture>
                                  <source srcSet={`${milaFrontViewWebp} 1x`} type='image/webp' />
                                  <source srcSet={`${milaFrontView} 1x`} type='image/png' />
                                  <img
                                    src={milaFrontView}
                                    alt='mila-front-view'
                                  />
                                </picture>
                              </FadeIn>
                            </LazyLoad>
                          </figure>
                          <h3 className='price-card__title'>
                            <strong>The Studio</strong>
                            {' '}
                            1x Mila
                          </h3>
                          <div className='price-card__description'>
                            <p>
                              Ideal for a studio apartment or any room up to
                              {' '}
                              {getPerfectSquare(1000, localization.region, measure)}
                              {' '}
                              {measure}
                              <sup>2</sup>
                              .
                            </p>
                          </div>
                          {(getTrue(ENABLE_PRODUCT_BLOCK_DISCOUNT) && x1unit.discount_price)
                            ? (
                              <div className='price-card__footer price-card__footer--discount'>
                                <a href='/buy' className='btn btn--small'>Order Now</a>

                                <span
                                  className='price-card__price'
                                >
                                  <strong>
                                    <span className='price-card__discount-percentage'>
                                      Save
                                      {' '}
                                      {Math.round(100 - ((x1unit.discount_price / x1unit.price) * 100))}
                                      %
                                    </span>
                                    {sign}
                                    {x1unit.discount_price}
                                  </strong>
                                </span>
                              </div>
                            ) : (
                              <div className='price-card__flex-footer'>
                                <a href='/buy' className='btn btn--small'>Order Now</a>
                                <span
                                  className='price-card__price'
                                >
                                  <strong>
                                    {sign}
                                    {x1unit.price}
                                  </strong>
                                </span>
                              </div>
                            )}
                        </div>
                      </motion.div>
                    </div>
                    <div
                      key={generateKey('product-item')}
                      className={`product-block__items-item ${currentIndex === 2 ? 'product-block__items-item--is-active' : ''}`}
                      ref={el => bodyRefs[2] = el}
                      onClick={() => setSlideIndex(1)}
                    >
                      <motion.h3
                        className='product-block__items-title'
                        onClick={() => clickHandler(2)}
                        initial={{ height: 0 }}
                        animate={{ height: (currentIndex === 1 || currentIndex === 3) ? 'auto' : 0 }}
                        transition={{ duration: 0.5 }}
                        style={{ overflow: 'hidden' }}
                      >
                        <strong>The One Bedroom</strong>
                        {' '}
                        2x Milas
                      </motion.h3>

                      <motion.div
                        className='price-card'
                        initial={{ height: 0 }}
                        animate={{ height: currentIndex === 2 ? 'auto' : 0 }}
                        transition={{ duration: 0.5 }}
                        style={{ overflow: 'hidden' }}
                      >
                        <div className='price-card__inner'>
                          <figure className='price-card__image'>
                            <LazyLoad>
                              <FadeIn>
                                <picture>
                                  <source srcSet={`${milaFrontViewWebp} 1x`} type='image/webp' />
                                  <source srcSet={`${milaFrontView} 1x`} type='image/png' />
                                  <img
                                    src={milaFrontView}
                                    alt='mila-front-view'
                                  />
                                </picture>
                              </FadeIn>
                            </LazyLoad>
                          </figure>
                          <motion.h3 className='price-card__title'>
                            <strong>The One Bedroom</strong>
                            {' '}
                            2x Milas
                          </motion.h3>
                          <div className='price-card__description'>
                            <p>
                              Perfect for a 1-bedroom apartment or any 2 rooms up to
                              {' '}
                              {getPerfectSquare(1000, localization.region, measure)}
                              {' '}
                              {measure}
                              <sup>2</sup>
                              {' '}
                              each.
                            </p>
                          </div>
                          {(getTrue(ENABLE_PRODUCT_BLOCK_DISCOUNT) && x2unit.discount_price)
                            ? (
                              <div className='price-card__footer price-card__footer--discount'>
                                <a href='/buy' className='btn btn--small'>Order Now</a>
                                <span
                                  className='price-card__price'
                                >
                                  <strong>
                                    <span className='price-card__discount-percentage'>
                                      Save
                                      {' '}
                                      {Math.round(100 - ((x2unit.discount_price / x2unit.price) * 100))}
                                      %
                                    </span>
                                    {sign}
                                    {x2unit.discount_price}
                                  </strong>
                                </span>
                              </div>
                            ) : (
                              <div className='price-card__flex-footer'>
                                <a href='/buy' className='btn btn--small'>Order Now</a>
                                <span
                                  className='price-card__price'
                                >
                                  <strong>
                                    {sign}
                                    {x2unit.price}
                                  </strong>
                                </span>
                              </div>
                            )}
                        </div>
                      </motion.div>
                    </div>
                    <div
                      key={generateKey('product-item')}
                      className={`product-block__items-item ${currentIndex === 3 ? 'product-block__items-item--is-active' : ''}`}
                      ref={el => bodyRefs[3] = el}
                      onClick={() => setSlideIndex(2)}
                    >
                      <motion.h3
                        className='product-block__items-title'
                        onClick={() => clickHandler(3)}
                        initial={{ height: 0 }}
                        animate={{ height: (currentIndex === 1 || currentIndex === 2) ? 'auto' : 0 }}
                        transition={{ duration: 0.5 }}
                        style={{ overflow: 'hidden' }}
                      >
                        <strong>The Two Bedroom</strong>
                        {' '}
                        3x Milas
                      </motion.h3>

                      <motion.div
                        className='price-card'
                        initial={{ height: 0 }}
                        animate={{ height: currentIndex === 3 ? 'auto' : 0 }}
                        transition={{ duration: 0.5 }}
                        style={{ overflow: 'hidden' }}
                      >
                        <div className='price-card__inner'>
                          <figure className='price-card__image'>
                            <LazyLoad>
                              <FadeIn>
                                <picture>
                                  <source srcSet={`${milaFrontViewWebp} 1x`} type='image/webp' />
                                  <source srcSet={`${milaFrontView} 1x`} type='image/png' />
                                  <img
                                    src={milaFrontView}
                                    alt='mila-front-view'
                                  />
                                </picture>
                              </FadeIn>
                            </LazyLoad>
                          </figure>
                          <h3 className='price-card__title'>
                            <strong>The Two Bedroom</strong>
                            {' '}
                            3x Milas
                          </h3>
                          <div className='price-card__description'>
                            <p>
                              Superb for a 2-bedroom apartment or any 3 rooms up to
                              {' '}
                              {getPerfectSquare(1000, localization.region, measure)}
                              {' '}
                              {measure}
                              <sup>2</sup>
                              {' '}
                              each.
                            </p>
                          </div>
                          {(getTrue(ENABLE_PRODUCT_BLOCK_DISCOUNT) && x3unit.discount_price)
                            ? (
                              <div className='price-card__footer price-card__footer--discount'>
                                <a href='/buy' className='btn btn--small'>Order Now</a>
                                <span
                                  className='price-card__price'
                                >
                                  <strong>
                                    <span className='price-card__discount-percentage'>
                                      Save
                                      {' '}
                                      {Math.round(100 - ((x3unit.discount_price / x3unit.price) * 100))}
                                      %
                                    </span>
                                    {sign}
                                    {x3unit.discount_price}
                                  </strong>
                                </span>
                              </div>
                            ) : (
                              <div className='price-card__flex-footer'>
                                <a href='/buy' className='btn btn--small'>Order Now</a>
                                <span
                                  className='price-card__price'
                                >
                                  <strong>
                                    {sign}
                                    {x3unit.price}
                                  </strong>
                                </span>
                              </div>
                            )}
                        </div>
                      </motion.div>
                    </div>
                  </div>
                )
            }
          </div>
        </div>
      </section>
    </AnimatePresence>
  );
};

export default ProductBlock;
